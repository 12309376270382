import { Component, OnInit } from '@angular/core';
import {
  Ae2HeaderWidgetConfig,
  Ae2HeaderLinkToPage,
  Ae2HeaderMenu,
} from '@angularecommerce/core/components/header-widget';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import {
  Ae2SignUpStepSignup,
  Ae2SignUpStepAddress,
  Ae2SignUpStepFitness,
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import {
  Ae2SignUpFieldGroup
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-stepper.model';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';
import { ae2SamePasswordValidator } from '@angularecommerce/core/functions/same-password-validator';
import { Ae2SettingsService } from '@angularecommerce/core/services/settings/settings.service';
import { Ae2AffiliateService } from '@angularecommerce/core/services/affiliate/affiliate.service';
class CustomAe2SignUpFieldGroup extends Ae2SignUpFieldGroup {
  constructor() {
    super();

    this.fields = [
      new Ae2SignUpField({
        name: 'firstName',
        internalname: 'first_name',
        label: 'Nome',
        validators: new Ae2SignUpFieldValidator({ required: true }),
        row: 1,
        size: '31.33%',
        onBlur: context => {
          const firstName = context.stepForm.get('firstName').value;
          const lastName = context.stepForm.get('lastName').value;
          const nickname = `${firstName} ${lastName}`;

          context.stepperConfig.savedData['nickname'] = nickname;
        }
      }),
      new Ae2SignUpField({
        name: 'lastName',
        internalname: 'last_name',
        label: 'Sobrenome',
        validators: new Ae2SignUpFieldValidator({ required: true }),
        row: 1,
        size: '31.33%',
        onBlur: context => {
          const firstName = context.stepForm.get('firstName').value;
          const lastName = context.stepForm.get('lastName').value;
          const nickname = `${firstName} ${lastName}`;

          context.stepperConfig.savedData['nickname'] = nickname;
        }
      }),
      new Ae2SignUpField({
        name: 'gender',
        label: 'Sexo',
        internalname: 'gender',
        validators: new Ae2SignUpFieldValidator({
          type: Ae2SignupFieldTypes.RADIO, required: true, options: [
            { value: 'M', label: 'M' },
            { value: 'F', label: 'F' }
          ]
        }),
        row: 1,
        class: 'singup-gender',
        size: '33%'
      }),
      new Ae2SignUpField({
        name: 'imNotBrazilian',
        label: 'Im not brazilian',
        validators: new Ae2SignUpFieldValidator({
          type: Ae2SignupFieldTypes.CHECKBOX,
          value: false
        }),
        row: 2,
        size: '31.33%',
        onChange: context => {
          const isNotBrazilian = context.stepForm.get('imNotBrazilian').value;

          const cpfField = this.fields.find(field => field.name === 'cpf');
          const passportField = this.fields.find(field => field.name === 'documentId');
          const mobileValidator = this.fields.find(field => field.name === 'mobilePhone');
          

          if (cpfField) {
              cpfField.isVisible = !isNotBrazilian;
          }

          if (passportField) {
              passportField.isVisible = isNotBrazilian;
          }
          if (mobileValidator && isNotBrazilian) {
            mobileValidator.validators = new Ae2SignUpFieldValidator({ required: true, maxlength: 16, minlength: 8 });
          } else {
            mobileValidator.validators = new Ae2SignUpFieldValidator({ required: true, minlength: 15, mask: '(99) 99999-9999' });
          }

          const nicknameValidator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'nickname');
          if (nicknameValidator && isNotBrazilian) {
            nicknameValidator.validators = new Ae2SignUpFieldValidator({ required: false });
          } else {
            nicknameValidator.validators = new Ae2SignUpFieldValidator({ required: true });
          }

          const zipValidator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'zip');
          if (zipValidator && isNotBrazilian) {
            zipValidator.validators = new Ae2SignUpFieldValidator({ required: false, maxlength: 9, minlength: 8, mask: '99999-999' });
          } else {
            zipValidator.validators = new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.CEP, required: true, mask: '99999-999', minlength: 9 });
          }

          const address1Validator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'address1');
          if (address1Validator && isNotBrazilian) {
            address1Validator.validators = new Ae2SignUpFieldValidator({ required: false });
          } else {
            address1Validator.validators = new Ae2SignUpFieldValidator({ required: true });
          }

          const address2Validator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'address2');
          if (address2Validator && isNotBrazilian) {
            address2Validator.validators = new Ae2SignUpFieldValidator({ required: false });
          } else {
            address2Validator.validators = new Ae2SignUpFieldValidator({ required: true });
          }

          const address4Validator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'address4');
          if (address4Validator && isNotBrazilian) {
            address4Validator.validators = new Ae2SignUpFieldValidator({ required: false });
          } else {
            address4Validator.validators = new Ae2SignUpFieldValidator({ required: true });
          }

          const cityValidator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'city');
          if (cityValidator && isNotBrazilian) {
            cityValidator.validators = new Ae2SignUpFieldValidator({ required: false });
          } else {
            cityValidator.validators = new Ae2SignUpFieldValidator({ required: true });
          }
          
          const stateValidator = context.stepperConfig.steppers[2].fields.find(field => field.name === 'state');
          if (stateValidator && isNotBrazilian) {
            stateValidator.validators = new Ae2SignUpFieldValidator({ required: false });
          } else {
            stateValidator.validators = new Ae2SignUpFieldValidator({ required: true });
          }

          context.changeFieldsVisibilityInCurrentForm(this.fields);
      }
      }),
      new Ae2SignUpField({
        name: 'cpf',
        label: 'CPF',
        validators: new Ae2SignUpFieldValidator({ required: true, CpfValidatorFn: true, mask: '999.999.999-99' }),
        row: 2,
        size: '31.33%'
      }),
      new Ae2SignUpField({
        name: 'documentId',
        label: 'Passaport',
        isVisible: false,
        validators: new Ae2SignUpFieldValidator({ required: true, maxlength: 16 }),
        row: 2,
        size: '31.33%'
      }),
      new Ae2SignUpField({
        name: 'dateOfBirth',
        internalname: 'date_of_birth',
        label: 'Data de Nascimento',
        validators: new Ae2SignUpFieldValidator({ required: true, mask: '99/99/9999', minlength: 10 }),
        row: 2,
        size: '31.33%'
      }),
      new Ae2SignUpField({
        name: 'mobilePhone',
        internalname: 'mobile_phone',
        label: 'Celular',
        validators: new Ae2SignUpFieldValidator({ required: true, minlength: 5, maxlength: 16, mask: '(99) 99999-9999' }),
        row: 2,
        size: '31.33%'
      })
    ];
  }
}

class CustomAe2SignUpStepSignup extends Ae2SignUpStepSignup {
  constructor(private ae2SettingsService: Ae2SettingsService,
    private ae2AffiliateService: Ae2AffiliateService) {
    super();
    let referencial = '';
    if (this.ae2AffiliateService.has() && this.ae2SettingsService.settings.users.affiliateCodeEnabled) {
      referencial = this.ae2AffiliateService.get();
    }
    this.fields = [
      new Ae2SignUpField({
        name: 'email',
        label: 'Email',
        validators: new Ae2SignUpFieldValidator({ required: true, validEmail: true, duplicateEmail: true }),
        row: 1,
        size: '48.5%'
      }),
      new Ae2SignUpField({
        name: 'referredAffiliateCode',
        label: 'Código de indicação',
        validators: new Ae2SignUpFieldValidator({ required: false, value: referencial }),
        row: 1,
        size: '48.5%',
        isVisible: this.ae2SettingsService.settings.users.affiliateCodeEnabled,
      }),
      new Ae2SignUpField({
        name: 'password',
        label: 'Senha',
        validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
        row: 2,
        size: '48.5%'
      }),
      new Ae2SignUpField({
        name: 'confirmPassword',
        label: 'Confirmar senha',
        validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
        row: 2,
        size: '48.5%'
      })
    ];

    this.syncValidators = ae2SamePasswordValidator('password', 'confirmPassword');
  }
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  providers: [Ae2SettingsService, Ae2AffiliateService]
})
export class HeaderComponent implements OnInit {

  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
  signupConfig: Ae2SignUpStepperConfig;

  constructor(private ae2SettingsService: Ae2SettingsService, private ae2AffiliateService: Ae2AffiliateService) {
  }

  ngOnInit(): void {
    this.createSignupConfig();

    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderLinkToPage('comprar', '/pacotes'),
      new Ae2HeaderLinkToPage('sobre', '/kore/metodo'),
      new Ae2HeaderLinkToPage('studios', '/unidades'),
      // new Ae2HeaderLinkToPage('kore', '/kore/metodo'),
      // new Ae2HeaderLinkToPage('instrutores', '/instrutores'),
      new Ae2HeaderLinkToPage('franquias', 'https://www.franquiakore.com.br'),
      new Ae2HeaderLinkToPage('kore at home', 'https://aulas.sweatify.com.br/cadastro'),
      new Ae2HeaderLinkToPage('Programa Fidelidade', '/kore-eventos/kore-fidelidade'),
      new Ae2HeaderLinkToPage('kore news', '/kore-eventos'),


    ];
    this.headerConfig.transparentOnRoutes = [];
    this.headerConfig.backgroundColor = '#ffffff';
  }

  createSignupConfig(): void {
    this.signupConfig = new Ae2SignUpStepperConfig([
      new CustomAe2SignUpStepSignup(this.ae2SettingsService, this.ae2AffiliateService),
      new CustomAe2SignUpFieldGroup(),
      new Ae2SignUpStepAddress(),
      // new Ae2SignUpStepFitness()
    ]);
  }

  goToPage(link: string): void {
    window.open(link, '_blank');
  }

}

