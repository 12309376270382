<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>

<ae2-header-widget [config]="headerConfig" [signupConfig]="signupConfig">
  <ng-template #headerTemplate let-header let-config="config" let-settings="settings">

    <ng-container *ngIf="header.isPreHeaderOpened">
      <ng-container *ngIf="!header.isMobile; else mobilePreHeader">
        <div class="ae2-header-widget-login-or-signup" fxLayout="row" fxLayoutAlign="center start">
          <ae2-sign-in-top class="pre-header-element"
            *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN"
            (onCloseLogin)="header.closeLoginOrSignup()" (onOpenSignup)="header.openSignup()"
            (onOpenForgot)="header.openForgot()">
          </ae2-sign-in-top>
          <ae2-sign-up-top class="pre-header-element ae2-sign-up-top"
            *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.SIGNUP"
            (onCloseSignup)="header.closeLoginOrSignup()" (onOpenLogin)="header.openLogin()"
            (onOpenForgot)="header.openForgot()" [templateTop]="signupTopTemplate"
            [templateBottom]="signupBottomTemplate" [config]="header.signupConfig">
          </ae2-sign-up-top>
          <ae2-forgot-password-top class="pre-header-element"
            *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT" (onOpenSignup)="header.openSignup()"
            (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
            (onNextStepForgot)="header.nextStepForgot()">
          </ae2-forgot-password-top>
          <ae2-forgot-password-done-top class="pre-header-element"
            *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
            (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()"
            (onCloseForgotDone)="header.closeLoginOrSignup()" (onOpenLoginDone)="header.openLogin()">
          </ae2-forgot-password-done-top>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #mobilePreHeader>
      <div class="ae2-header-widget-pre-header-mobile">
        <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN"
          (onCloseLogin)="header.closeLoginOrSignup()" (onOpenSignup)="header.openSignup()"
          (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
        <ae2-sign-up-top class="pre-header-element"
          *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.SIGNUP"
          (onCloseSignup)="header.closeLoginOrSignup()" (onOpenLogin)="header.openLogin()"
          (onOpenForgot)="header.openForgot()" [templateTop]="signupTopTemplate" [templateBottom]="signupBottomTemplate"
          [config]="header.signupConfig"></ae2-sign-up-top>
        <ae2-forgot-password-top class="pre-header-element"
          *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT" (onOpenSignup)="header.openSignup()"
          (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
          (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
        <ae2-forgot-password-done-top class="pre-header-element"
          *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
          (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()"
          (onCloseForgotDone)="header.closeLoginOrSignup()" (onOpenLoginDone)="header.openLogin()">
        </ae2-forgot-password-done-top>
      </div>
    </ng-template>

    <div fxHide="true" fxHide.xs="false" class="ae2-header-widget-top" fxLayout="row"
      fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
      <!-- <ae2-header-social-widget [template]="header.headerSocialTemplate" [settings]="settings.site"></ae2-header-social-widget> -->
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <!-- <ae2-header-login-widget [template]="header.headerLoginTemplate" [isLoginInHeader]="config.isLoginInHeader" (onOpenLogin)="header.openLogin()"
                    (onOpenSignup)="header.openSignup()"></ae2-header-login-widget> -->
        <!-- <ae2-header-unit-selector-widget [template]="header.headerUnitSelectorTemplate"></ae2-header-unit-selector-widget> -->
        <!-- <app-unit-selection-component></app-unit-selection-component> -->
      </div>
    </div>
    <div class="ae2-header-widget-bottom" fxLayout="row" fxLayoutAlign="space-between stretch"
      fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" fxLayoutGap="15px">
      <div class="ae2-header-mobile-menu" fxHide.gt-xs (click)="sidenavComponent.openSidenav()">
        <mat-icon>menu</mat-icon>
      </div>
      <ae2-header-logo-widget fxFlex="12%" fxFlex.xs="auto" fxFlex.sm="77%" [template]="header.headerLogoTemplate"
        [isTransparent]="config.isTransparent"></ae2-header-logo-widget>
      <div class="linkButtom" fxHide fxHide.xs="false" fxHide.sm="false" fxFlex.sm="23%" fxFlex.xs="auto">
        <app-unit-selection-component [labelInButtom]="'reservar/comprar'"></app-unit-selection-component>
      </div>
      <ae2-header-menu-widget fxFlex="calc(88% - 15px)" fxHide.xs="true" fxHide.sm="true"
        [template]="header.headerMenuTemplate" [isSubmenuOpened]="header.submenuVisibility" [items]="config.menuItems"
        (onSubitemsShow)="header.populateSubitems($event)">
        <ng-template #headerSocialWidget let-menu>
          <app-menu-default (openSignIn)="header.openLogin()" (openSignUp)="header.openSignup()"></app-menu-default>
        </ng-template>
      </ae2-header-menu-widget>
    </div>
    <ae2-header-sidenav-widget #sidenavComponent [template]="headerSidenavTemplate" [items]="config.menuItems"
      (onOpenLogin)="header.openLogin()" (onOpenSignup)="header.openSignup()">
    </ae2-header-sidenav-widget>
    <!-- <ng-template #headerSidenavTemplate let-sidenav>
            ola
        </ng-template> -->
  </ng-template>

</ae2-header-widget>

<ng-template #headerSidenavTemplate let-sidenav>
  <div class="ae2-header-sidenav-closer" [class.active]="sidenav.isOpened" (click)="sidenav.closeSidenav()">&nbsp;</div>
  <div class="ae2-header-sidenav" [class.active]="sidenav.isOpened">

    <ng-template #menuItems>

      <mat-divider></mat-divider>
      <ng-container *ngFor="let item of sidenav.items.items">
        <div *ngIf="['PAGE','SESSION'].indexOf(item.type) >= 0" class="ae2-header-sidenav-item" fxLayout="row"
          fxLayoutAlign="start center">
          <div class="ae2-header-sidenav-item-page" *ngIf="item.type === 'PAGE'">

            <ng-container *ngIf="item.label == 'comprar'">
              <div fxLayout fxLayoutAlign="space-between center">
                <a [routerLink]="item.url" (click)="sidenav.closeSidenav()" class="sidenav-link"
                  [class.sidenav-link-bold]="item.label == 'comprar' || item.label == 'reservar aulas'">
                  <span>{{ item.label }}</span>
                </a>
                <div class="bagCustom" fxLayout fxLayoutAlign="space-between center">
                  <ae2-bag>
                    <ng-template #templateBagRef let-total="total">
                      <button routerLink="/checkout" class="navDefault_container_button" mat-button>
                        <i class="bai">{{total}}</i>
                      </button>
                    </ng-template>
                  </ae2-bag>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.label != 'comprar'">
              <ng-container *ngIf="item.url.substr(0, 8) == 'https://'">
                <a (click)="sidenav.closeSidenav(); goToPage(item.url)" class="sidenav-link"
                  [class.sidenav-link-bold]="item.label == 'comprar' || item.label == 'reservar aulas'">
                  <span>{{ item.label }}</span>
                </a>
              </ng-container>
              <!-- <ng-container *ngIf="item.label == 'franquias'">
                <a (click)="sidenav.closeSidenav(); goToPage('https://www.franquiakore.com.br')" class="sidenav-link"
                  [class.sidenav-link-bold]="item.label == 'comprar' || item.label == 'reservar aulas'">
                  <span>{{ item.label }}</span>
                </a>
              </ng-container> -->
              <ng-container *ngIf="item.label != 'franquias' && item.url.substr(0, 8) != 'https://'">
                <a [routerLink]="item.url" (click)="sidenav.closeSidenav()" class="sidenav-link"
                  [class.sidenav-link-bold]="item.label == 'comprar' || item.label == 'reservar aulas'">
                  <span>{{ item.label }}</span>
                </a>
              </ng-container>
            </ng-container>

          </div>
          <div class="ae2-header-sidenav-item-session" *ngIf="item.type === 'SESSION'">
            <a (click)="sidenav.navigator(item.url); sidenav.closeSidenav()" class="sidenav-link"
              [class.sidenav-link-bold]="item.label == 'comprar' || item.label == 'reservar aulas'">
              <span>{{ item.label }}</span>
            </a>
          </div>
        </div>
      </ng-container>

    </ng-template>

    <ng-template #notLoggedIn>
      <div fxLayout="column" fxLayoutAlign="start stretch">

        <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openLogin()" fxLayout="row"
          fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-icon>account_circle</mat-icon>
          <span>Entrar</span>
        </div>

        <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openSignup()" fxLayout="row"
          fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-icon>mode_edit</mat-icon>
          <span>Cadastre-se</span>
        </div>

        <mat-divider></mat-divider>

        <ng-template [ngTemplateOutlet]="menuItems"></ng-template>

      </div>
    </ng-template>

    <ng-container *ngIf="sidenav.isLoggedIn; else notLoggedIn">


      <div fxLayout="column" fxLayoutAlign="start stretch">
        <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav()" routerLink="/minha-conta/meus-dados"
          fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-icon>account_circle</mat-icon>
          <span>Olá, {{(sidenav.authStore$ | async)?.user?.firstName || '' }} ({{(sidenav.authStore$ |
                        async)?.totalCredits
                        || 0}} créditos)
          </span>
        </div>
        <ng-template [ngTemplateOutlet]="menuItems"></ng-template>
        <div class="ae2-header-sidenav-item sidenav-link-black" (click)="sidenav.logOut(); sidenav.closeSidenav()"
          fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-icon>exit_to_app</mat-icon> logout
        </div>
        <mat-divider></mat-divider>
        <div>
          <mat-accordion>
            <mat-expansion-panel class="mdepanel" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Minha conta
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/aulas-agendadas"
                  class="ae2-header-sidenav-item-myaccount sidenav-link-bold" routerLinkActive="active-link"
                  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  Aulas agendadas
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/aulas-realizadas"
                  class="ae2-header-sidenav-item-myaccount sidenav-link-bold" routerLinkActive="active-link"
                  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  Aulas realizadas
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/meus-creditos"
                  class="ae2-header-sidenav-item-myaccount sidenav-link-bold" routerLinkActive="active-link"
                  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  Meus créditos
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/pedidos"
                  class="ae2-header-sidenav-item-myaccount sidenav-link-bold" routerLinkActive="active-link"
                  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  Meus pedidos
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/desafios"
                  class="ae2-header-sidenav-item-myaccount sidenav-link-bold bg-theme" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Desafios
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/minhas-assinaturas"
                  class="ae2-header-sidenav-item-myaccount" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Minhas assinaturas
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/gifts"
                  class="ae2-header-sidenav-item-myaccount" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Gift card
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/meus-dados"
                  class="ae2-header-sidenav-item-myaccount" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Meus dados
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/performance"
                  class="ae2-header-sidenav-item-myaccount" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Performance
                </div>
                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/cartoes-de-credito"
                  class="ae2-header-sidenav-item-myaccount" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Cartões de crédito
                </div>

                <div (click)="sidenav.closeSidenav()" routerLink="/minha-conta/bring-a-friend"
                  class="ae2-header-sidenav-item-myaccount" routerLinkActive="active-link" fxLayout="row"
                  fxLayoutGap="10px" fxLayoutAlign="start center">
                  Bring a Friend
                </div>

              </div>

            </mat-expansion-panel>
          </mat-accordion>
        </div>


      </div>
    </ng-container>

  </div>
</ng-template>
